<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <div class="router-view-container">
        <div class="flex items-center space-x-4 mb-5">
          <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
            <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
          </vs-button>

          <h1 class="page_title">{{ $route.meta.title }}</h1>
        </div>

        <main v-if="is_API_loaded || isNewPost" class="router-view-container space-y-5">
          <!-- 1. -->

          <!-- 如果是新增文章，就隱藏此區塊 -->
          <div v-if="!isNewPost" class="flex flex-wrap w-full items-center justify-between">
            <h1 class="text-xl font-medium text-gray-text_dark">{{ title }}</h1>
            <div class="flex items-center space-x-2 flex-shrink-0">
              <a :href="_wlink" target="_blank">
                <vs-button size="large" transparent>
                  <svg-icon icon-class="eye_view" className="primary xl"></svg-icon>
                  <span class="font-medium">檢視</span>
                </vs-button>
              </a>
              <vs-button @click="copy_dialog = true" size="large" transparent>
                <svg-icon icon-class="copy" className="primary"></svg-icon>
                <span class="font-medium ml-1">複製</span>
              </vs-button>
            </div>
          </div>
          <!-- <h1 v-if="!isNewPost" class="text-xl font-medium text-gray-text_dark lg:hidden">{{ title }}</h1> -->

          <!-- 新增文章後顯示 -->
          <transition name="fade">
            <section v-if="alertAfterCreated">
              <vs-alert color="success" gradient>
                <template #icon>
                  <svg-icon icon-class="success" className="xxl"></svg-icon>
                </template>
                <template #title> {{ alertTitle }} 已新增成功 </template>
                <a :href="_wlink" target="_blank">在前台中檢視</a> 或
                <router-link :to="{ name: 'menus' }">加入目錄選單</router-link>
              </vs-alert>
            </section>
          </transition>

          <!-- 若沒有部落格，顯示提醒 -->
          <transition name="fade">
            <section v-if="isShowBlogMsg && blogs.length === 0">
              <vs-alert color="danger" gradient>
                <template #icon>
                  <svg-icon icon-class="caution" className="xxl"></svg-icon>
                </template>
                <template #title>您還尚未創立部落格！</template>
                請先前往 <router-link :to="{ name: 'new-blog' }">新增部落格</router-link>，再回來新增文章。
              </vs-alert>
            </section>
          </transition>

          <!-- 2. -->
          <section class="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-4">
            <!-- 2.1.1 -->
            <div class="w-full md:w-14/20 space-y-4 flex-shrink-0">
              <article class="box">
                <div>
                  <h1 class="title">標題 *</h1>
                  <ValidationProvider mode="aggressive" name="標題" rules="required|max:50" v-slot="{ errors }" tag="div" class="relative">
                    <vs-input @input="titleChange" class="w-full" v-model="title" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>

                <div>
                  <h1 class="title">短描述</h1>
                  <h2 class="text-gray-400 text-xs mb-1">※ 短描述會呈現在文章列表中</h2>

                  <ValidationProvider mode="aggressive" name="短描述" rules="max:100" v-slot="{ errors }" tag="div" class="relative">
                    <vs-input class="w-full" v-model="descs" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>

                <div>
                  <h1 class="title">內容</h1>
                  <editor ref="editor" v-model="content" :content="content" @input="contentChange"></editor>
                </div>
              </article>

              <!-- 2.1.2 SEO -->
              <article class="box overflow-hidden" :class="[seoSectionOpen ? '' : 'h-16']">
                <div class="flex items-center justify-between">
                  <h1 class="bigTitle">SEO 搜尋引擎最佳化</h1>
                  <vs-button v-if="!seoSectionOpen" @click="seoSectionOpen = true" transparent color="success">編輯 SEO</vs-button>
                </div>

                <section
                  class="transition-transform duration-100 transform scale-y-0 origin-top space-y-6"
                  :class="[seoSectionOpen ? 'scale-y-100' : '']"
                >
                  <div class="space-y-1">
                    <h1 class="text-success font-medium text-base">{{ seoTitle }}</h1>
                    <p class="text-green-700 text-sm break-all" v-if="seoUrl">{{ _seolink }}{{ seoUrl }}</p>
                    <p class="text-sm text-limit-2" style="max-height: 2.5em">{{ _seoContent }}</p>
                  </div>
                  <div>
                    <h1 class="title">網頁標題</h1>
                    <ValidationProvider mode="aggressive" name="網頁標題" rules="max:100" v-slot="{ errors }">
                      <vs-input class="w-full" v-model="seoTitle" />
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>

                  <div>
                    <h1 class="title">網頁網址</h1>
                    <div class="flex items-center border border-gray-border rounded-xl pl-3">
                      <div>
                        <span class="whitespace-nowrap">{{ _seolink }}</span>
                      </div>
                      <ValidationProvider class="w-full" mode="aggressive" name="網頁網址" rules="max:100" v-slot="{ errors }">
                        <vs-input type="url" id="noBorderInput" class="w-full" v-model.trim="seoUrl" />
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div>
                    <h1 class="title">網頁描述</h1>
                    <myTextarea autoHeight v-model="_seoContent"></myTextarea>
                  </div>
                </section>
              </article>

              <!-- 2.1.3 讀者留言 -->
              <article
                ref="scrollTo"
                v-if="formMsg != '' || formMsg.length > 0"
                class="box p-0 overflow-hidden"
                style="padding: 16px 0px"
                :class="[formOpen ? 'h-auto' : 'h-16']"
              >
                <section class="flex items-center justify-between px-4">
                  <h1 class="bigTitle">
                    讀者留言<span v-if="!formOpen" class="text-xs text-gray-400 ml-3">(共 {{ pagination.pcounts }} 筆 )</span>
                  </h1>
                  <vs-button @click="formOpen = !formOpen" transparent color="success">
                    <span v-if="formOpen">收合全部</span>
                    <span v-else>展開全部</span>
                  </vs-button>
                </section>

                <div
                  class="transition-transform duration-100 transform origin-top space-y-6"
                  :class="[formOpen ? 'scale-y-100' : 'scale-y-0']"
                >
                  <!-- 標題 -->
                  <section class="px-4 flex items-center">
                    <div>
                      <vs-checkbox
                        class="formCheckbox"
                        v-if="formIndeterminate"
                        indeterminate
                        v-model="formIndeterminate"
                        @click="indeterminateHandleClick"
                        ><span class="text-xs">已選取 {{ formSelected.length }} 筆</span></vs-checkbox
                      >

                      <vs-checkbox class="formCheckbox" v-else v-model="formSelectAll" @click="checkAllHandleClick">
                        <span v-if="formSelectAll" class="text-xs">已選取 {{ formSelected.length }} 筆</span>
                        <span v-else class="text-xs">此頁共 {{ formMsg.length }} 筆</span>
                      </vs-checkbox>
                    </div>

                    <transition name="fade">
                      <vs-button @click="deleteMsg_dialog = true" border v-if="formSelected != ''">刪除</vs-button>
                    </transition>
                  </section>

                  <!-- 留言列表 -->
                  <section v-for="(item, index) in formMsg" :key="item.sbpino" class="space-y-4">
                    <div class="flex items-center space-x-4 hover:bg-gray-background px-4 py-4">
                      <vs-checkbox @click="checkBoxHandleClick" v-model="formSelected" :val="item.sbpino"></vs-checkbox>
                      <div class="space-y-2 w-full">
                        <h1 class="text-gray-text_dark font-medium">{{ item.uname }}</h1>
                        <h2 v-if="item.tel" class="text-gray-400">{{ item.tel }}</h2>
                        <h2 class="text-gray-400">{{ item.email }}</h2>
                        <p class="leading-tight whitespace-pre-line">{{ item.cont }}</p>

                        <div class="flex items-center justify-between">
                          <p class="text-right text-gray-400 text-xs">{{ item.cdate }}</p>
                          <vs-button
                            @click=";(replyTextareaIndex = index), (formReplyMsg = item.recont)"
                            v-if="replyTextareaIndex !== index"
                            transparent
                            color="success"
                          >
                            <span v-if="item.recont">編輯回覆</span>
                            <span v-else>回覆留言</span>
                          </vs-button>
                        </div>
                      </div>
                    </div>

                    <!-- 回覆區塊 -->
                    <div class="replyMsg_section">
                      <transition name="fade">
                        <section v-if="replyTextareaIndex === index">
                          <ValidationObserver v-slot="{ invalid }" class="flex items-end space-x-2">
                            <div class="w-full">
                              <h1 class="title">回覆訊息</h1>

                              <div class="relative">
                                <ValidationProvider mode="aggressive" name="回覆訊息" rules="max:200|required" v-slot="{ errors }">
                                  <myTextarea autoHeight v-model.trim="formReplyMsg"></myTextarea>
                                  <errorDiv>{{ errors[0] }}</errorDiv>
                                </ValidationProvider>
                              </div>
                            </div>
                            <vs-button
                              :disabled="invalid"
                              class="flex-shrink-0"
                              size="large"
                              @click="sentMsg(item.sbpino, index, invalid)"
                              :ref="`sentBtn${index}`"
                            >
                              <span v-if="item.recont" class="font-medium">更新</span>
                              <span v-else class="font-medium">送出</span>
                            </vs-button>
                          </ValidationObserver>
                        </section>
                      </transition>

                      <section v-if="item.recont">
                        <h1 class="title">已回覆訊息</h1>
                        <div class="space-y-4">
                          <div class="flex flex-col bg-gray-background p-4 rounded-xl replied_section">
                            <p class="leading-tight mb-4" v-dompurify-html="item.recont.replace(/\n/g, '<br>')"></p>
                            <div class="flex items-center justify-between text-xs text-gray-400">
                              <p>{{ item.redate }}</p>
                              <p>{{ item.reperson }}</p>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </section>

                  <!-- pagination -->
                  <div v-if="pagination.tpages > 1" class="flex flex-col items-center space-y-2">
                    <vs-pagination @click.native="changePagination" not-arrows v-model="currentPage" :length="pagination.tpages" />
                    <span class="text-xs text-gray-400">總共 {{ pagination.pcounts }} 筆</span>
                  </div>
                </div>
              </article>
            </div>

            <!-- 2.2 -->
            <div class="flex-grow space-y-4">
              <div class="box">
                <h1 class="bigTitle">公開狀態</h1>
                <div class="flex flex-col items-start space-y-2">
                  <vs-radio v-model="isPublic" :val="1">公開</vs-radio>
                  <vs-radio v-model="isPublic" :val="0">隱藏<span v-if="isSchedule"> (已排程)</span></vs-radio>
                </div>
                <div key="1" v-if="!isPublic" class="flex flex-col items-end space-y-6">
                  <vs-button key="2" v-if="!schedule" @click="setSchedule" transparent color="success"> 設定排程 </vs-button>

                  <div key="3" v-if="schedule" class="space-y-6 pt-6 w-full">
                    <vs-input type="date" :min="_minDate" :max="_maxDate" v-model="date" label="公開日期" />
                    <vs-input type="time" v-model="time" label="公開時間" />
                  </div>
                  <vs-button key="4" v-if="schedule" @click="cancelSchedule" transparent color="success">取消排程</vs-button>
                </div>
              </div>
              <div class="box">
                <div>
                  <h1 class="bigTitle">部落格 *</h1>
                  <p class="text-gray-400 mt-2 text-xs">※ 選擇要將此文章加入到哪個部落格之中</p>
                </div>

                <div class="relative">
                  <ValidationProvider name="部落格" rules="required" v-slot="{ errors }">
                    <vs-select v-if="blogs.length" placeholder="請選擇部落格以加入此文章" v-model="sbno">
                      <vs-option v-for="blog in blogs" :key="blog.sbno" :label="blog.title" :value="blog.sbno">{{ blog.title }}</vs-option>
                    </vs-select>
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>
              <div class="box">
                <h1 class="bigTitle">封面圖片</h1>
                <div class="upload aspect-ratio-1-1_global_style">
                  <img v-if="img" :src="img" class="uploadImg" />
                  <div v-else class="upload_icon">
                    <svg-icon icon-class="upload" className="gray-divide" style="width: 3rem; height: 3rem"></svg-icon>
                    <p class="text-gray-400">點擊上傳或拖曳圖片</p>
                  </div>
                  <input type="file" @change="uploadImg" accept="image/*" class="img_input" />
                </div>
                <vs-button v-if="img" @click="img = ''" transparent danger style="margin-right: 0; margin-left: auto">移除</vs-button>
              </div>
              <div class="box">
                <h1 class="bigTitle">顯示設定</h1>
                <vs-checkbox v-model="ispimg">顯示封面圖片於文章內頁</vs-checkbox>
              </div>
              <div class="box">
                <div>
                  <h1 class="bigTitle">功能模組</h1>
                  <p class="text-gray-400 mt-2 text-xs">※ 替此文章添加自定義功能</p>
                </div>
                <div class="space-y-1">
                  <vs-checkbox v-model="isTable">讀者留言</vs-checkbox>
                  <vs-checkbox v-model="isAddress"> 地圖 </vs-checkbox>
                </div>
                <div>
                  <div class="relative" v-if="isAddress">
                    <ValidationProvider mode="aggressive" name="地址" :rules="`${isAddress ? 'required|max:100' : ''}`" v-slot="{ errors }">
                      <vs-input class="pt-5" label="地址" placeholder="例如：台北市信義區信義路五段7號" v-model.trim="address"></vs-input>
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                    <iframe
                      class="mt-4 rounded-xl"
                      v-if="address"
                      width="100%"
                      height="200"
                      style="border: 0"
                      loading="lazy"
                      allowfullscreen
                      :src="`https://www.google.com/maps/embed/v1/place?key=AIzaSyCO1kWn5gusfwKabYXEwBKaB1btJfxHyWI&q=${address}`"
                    >
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <hr />

          <section class="flex items-center justify-between">
            <vs-button @click="deletePost_dialog = true" v-if="!isNewPost" size="large" color="danger" border>
              <span class="font-medium">刪除文章</span>
            </vs-button>
            <vs-button to="/admin/pages" v-else size="large" border>
              <span class="font-medium">取消</span>
            </vs-button>
            <vs-button size="large" ref="savePost" :disabled="invalid || !sbno" @click="savePost(invalid)">
              <span class="font-medium">儲存文章</span>
            </vs-button>
          </section>
        </main>
      </div>
    </ValidationObserver>

    <!-- 骨架屏 -->
    <main v-if="!is_API_loaded && !isNewPost" class="router-view-container space-y-4">
      <section class="flex items-center space-x-4 justify-between">
        <skeleton width="48px" />

        <div v-if="!isNewPost" class="flex lg:w-full items-center justify-between">
          <skeleton class="hidden lg:block" width="50%" height="40px" />
          <div class="flex items-center space-x-2">
            <skeleton width="82px" />
            <skeleton width="82px" />
          </div>
        </div>
      </section>
      <skeleton v-if="!isNewPost" class="lg:hidden" width="80%" height="40px" />

      <section class="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-4">
        <div class="w-full md:w-14/20 space-y-4">
          <article class="box">
            <skeleton />

            <div>
              <skeleton height="200px" />
            </div>
          </article>

          <article class="box">
            <skeleton />
          </article>
        </div>

        <div class="flex-grow space-y-4">
          <div class="box">
            <skeleton />
            <skeleton />
            <skeleton />
          </div>
          <div class="box">
            <skeleton />
            <skeleton />
            <skeleton />
          </div>
        </div>
      </section>

      <hr />

      <section class="flex items-center justify-between">
        <skeleton width="82px" />
        <skeleton width="82px" />
      </section>
    </main>

    <!-- 刪除文章 dialog -->
    <vs-dialog overflow-hidden blur v-model="deletePost_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除此文章？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除的文章無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="deletePost_dialog = false">取消</vs-button>
          <vs-button @click="deletePost" size="large" transparent color="danger" ref="deletePostBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog>

    <!-- 刪除留言 dialog -->
    <vs-dialog overflow-hidden blur v-model="deleteMsg_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除 {{ formSelected.length }} 筆留言？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除的留言無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="deleteMsg_dialog = false">取消</vs-button>
          <vs-button @click="deleteMsg" size="large" transparent color="danger" ref="deleteMsgBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog>

    <!-- 複製文章 dialog -->
    <vs-dialog overflow-hidden blur v-model="copy_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要複製此文章？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">※ 複製後的新文章，預設公開狀態為「隱藏」，之後請記得公開喔！</p>
      </div>
      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="copy_dialog = false">取消</vs-button>
          <vs-button @click="copyPost" size="large" transparent ref="copyMsgBtn" color="success">複製</vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import editor from '@/components/editor.vue'
export default {
  name: 'post',
  components: {
    editor,
  },
  data() {
    return {
      is_API_loaded: false,
      isNewPost: false,
      blogs: [], // 部落格清單
      isShowBlogMsg: false, // 讀取完部落格清單後開啟
      sbno: '', // 部落格碼
      img: '', // 封面圖片
      descs: '', // 短描述
      title: '',
      content: '',
      seoTitle: '',
      seoUrl: '',
      seoContent: '',
      seoSectionOpen: false,
      titleSync: true,
      urlSync: true,
      contentSync: true,
      isPublic: 1,
      isSchedule: false, // 判斷此文章有沒有排程
      schedule: false, // 設定排程按鈕
      date: '2000-01-01',
      time: '23:59',
      isAddress: false,
      isTable: '',
      address: '',
      addressBackup: '',
      deletePost_dialog: false,
      alertAfterCreated: false, // 新增成功後呈現alert訊息
      alertTitle: '',
      formMsg: [], // 表單留言訊息
      pagination: {}, //表單留言頁籤
      currentPage: 1,
      formSelected: [], // 被選擇的表單訊息
      formSelectAll: false, // 全選
      formIndeterminate: false, // checkbox中間狀態
      deleteMsg_dialog: false, // 刪除留言dialog
      formOpen: false, // 展開/隱藏留言區塊
      copy_dialog: false, // 複製dialog
      formReplyMsg: '', // 回覆訊息
      replyTextareaIndex: null, // 開啟中的留言textarea index
      wlink: '',
      ispimg: true,
    }
  },
  computed: {
    _dateTime() {
      if (this.isPublic || this.schedule) {
        return this.date + ' ' + this.time + ':00'
      } else {
        return '2099-12-31' + ' ' + '23:59' + ':59'
      }
    },
    _minDate() {
      // 可選擇的最小日期
      const t = new Date()

      let year = t.getFullYear()
      let month = t.getMonth() + 1
      let date = t.getDate()
      if (date < 10) date = `0${date}`
      if (month < 10) month = `0${month}`
      return `${year}-${month}-${date}`
    },
    _maxDate() {
      // 可選擇的最大日期
      let now = Date.now() + 1000 * 60 * 60 * 24 * 365 * 10 // 獲取現在毫秒數 + 10年

      const t = new Date(now)

      let year = t.getFullYear()
      let month = t.getMonth() + 1
      let date = t.getDate()
      if (date < 10) date = `0${date}`
      if (month < 10) month = `0${month}`
      return `${year}-${month}-${date}`
    },
    // 把多選的checkbox陣列轉字串
    _selectedToStr() {
      let sbpno = this.$route.params.id
      let str = ''
      this.formSelected.forEach((item, index) => {
        if (index > 0) {
          item = `;${sbpno},${item}`
        } else {
          item = `${sbpno},${item}`
        }

        str += item
      })
      return str
    },
    _seoContent: {
      get: function () {
        return this.seoContent
          .replace(/<[^>]+>/g, '') // html標籤去除
          .replace(/&nbsp;/g, ' ') // &nbsp;轉空白
          .substr(0, 200) // 限制200字
      },
      set: function (val) {
        this.seoContent = val
      },
    },
    _wlink() {
      return this.$store.state.getWebData.param.wlink + this.wlink
    },
    _seolink() {
      if (this.isNewPost) return this._wlink + '/blog/blogname/'
      if (!this.isNewPost) {
        let i = this._wlink.lastIndexOf('/') // 最後一個/的索引
        return this._wlink.substr(0, i + 1)
      }
    },
  },
  watch: {
    $route(to, from) {
      this.isNewPostFunc()
    },
    seoTitle(val) {
      if (val !== this.title) this.titleSync = false
      if (val === '') {
        // this.seoTitle = this.title
        this.titleSync = true
      }
    },
    seoUrl(val) {
      if (val !== this.title || this.isNewPost === false) this.urlSync = false
      if (val === '') {
        // this.seoUrl = this.title
        this.urlSync = true
      }
    },
    seoContent(val) {
      if (val !== this.content) this.contentSync = false
      if (val === '') {
        // this.seoContent = this.content
        this.contentSync = true
      }
    },
    isPublic(val) {
      if (val) {
        this.date = '2000-01-01'
        this.time = '23:59'
        this.schedule = false
        this.isSchedule = false
      }
    },
    isAddress(val) {
      if (!val) {
        this.addressBackup = this.address
        this.address = ''
      } else {
        this.address = this.addressBackup
      }
    },
  },
  created() {
    this.getBlogsList()
    this.isNewPostFunc()
  },
  methods: {
    isNewPostFunc() {
      // 判斷是新增文章 還是 編輯文章
      this.$route.name === 'new-post' ? (this.isNewPost = true) : (this.isNewPost = false)

      if (!this.isNewPost) this.loadPost()
    },
    titleChange(value) {
      // 若sync = true，賦值到seo
      if (this.titleSync) this.seoTitle = value
      if (this.urlSync) this.seoUrl = value
    },
    contentChange(value) {
      // 若sync = true，賦值到seo
      if (this.contentSync) this.seoContent = value
    },
    // 設定排程 取得現在時間
    setSchedule() {
      this.schedule = true

      let now = Date.now() + 1000 * 60 * 30 // 獲取現在毫秒數 + 30分鐘

      const t = new Date(now)

      let year = t.getFullYear()
      let month = t.getMonth() + 1
      let date = t.getDate()
      let hour = t.getHours()
      let min = t.getMinutes()

      if (date < 10) date = `0${date}`
      if (month < 10) month = `0${month}`
      if (min < 10) min = `0${min}`

      this.date = `${year}-${month}-${date}`
      this.time = `${hour}:${min}`
    },
    // 取消排程
    cancelSchedule() {
      this.schedule = false
      this.isSchedule = false
      this.date = '2099-12-31'
      this.time = '23:59'
    },
    // 2.5.7 文章-讀取
    loadPost() {
      this.$axios({
        url: 'front/pages/blogs/getPagesUnit.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          sbpno: this.$route.params.id,
        },
      }).then((res) => {
        console.log(res, '讀取')
        if (res.data.Success) {
          this.pagination = res.data.Data.page3

          let data2 = res.data.Data.data2

          // 時間
          let dt = data2.rtime.split(' ')
          this.date = dt[0]
          // let index = dt[1].lastIndexOf(':') // 抓出最後一個:索引
          // this.time = dt[1].slice(0, index) // 只保留0~index中的字
          this.time = dt[1]
          //

          // 若有排程，設定的時間預設打開
          if (data2.isrtime === 0 && dt[0] !== '2099-12-31') {
            this.isSchedule = true
            this.schedule = true
          }

          this.isPublic = data2.isrtime

          // 地址有資料才打勾
          this.address = data2.address
          this.addressBackup = data2.address
          if (data2.address) this.isAddress = true
          //
          //
          data2.istable ? (this.isTable = true) : (this.isTable = false)
          this.title = data2.title
          this.content = data2.cont
          this.seoTitle = data2.mtitle
          this.seoContent = data2.mdescs
          this.seoUrl = data2.mpages
          data2.bimg === 'data:;base64,\r\n' ? (this.img = data2.img) : (this.img = data2.bimg)
          this.sbno = data2.sbno
          this.formMsg = res.data.Data.data3
          this.descs = data2.descs
          this.wlink = data2.wlink

          data2.ispimg ? (this.ispimg = true) : (this.ispimg = false)

          this.is_API_loaded = true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 2.5.8 文章-新增
    // 2.5.8 文章-修改
    savePost(invalid) {
      this.alertAfterCreated = false
      // 如果驗證未通過，return
      if (invalid) return

      const loading = this.$vs.loading({
        target: this.$refs.savePost,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      let url = '' // api url

      let mdescs = '' // seo網頁描述
      if (this._seoContent === '') {
        // 若seo網頁描述被清空，把內容塞回去
        mdescs = this.content
          .replace(/<[^>]+>/g, '') // html標籤去除
          .replace(/&nbsp;/g, ' ') // &nbsp;轉空白
          .substr(0, 200) // 限制200字
      } else {
        mdescs = this._seoContent
      }

      let mpages = '' // 網頁網址
      if (this.seoUrl === '') {
        mpages = this.title.replace(/[&|\*^'"{}[\]%$:;=#@!/?\s*\\\\<>,.()~+_`]/g, '-')
      } else {
        mpages = this.seoUrl.replace(/[&|\*^'"{}[\]%$:;=#@!/?\s*\\\\<>,.()~+_`]/g, '-')
      }

      let data = {
        lang: 0,
        rtime: this._dateTime,
        title: this.title,
        cont: this.content,
        address: this.address,
        istable: this.isTable ? 1 : 0,
        mtitle: this.seoTitle ? this.seoTitle : (this.seoTitle = this.title),
        mdescs: mdescs,
        mpages: mpages,
        sbno: this.sbno,
        img: this.img,
        descs: this.descs,
        ispimg: this.ispimg ? 1 : 0,
      }

      if (this.isNewPost) {
        url = 'front/pages/blogs/setPages.php'
      } else {
        url = 'front/pages/blogs/uPages.php'
        data.sbpno = this.$route.params.id
      }

      this.$axios({
        url: url,
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: data,
      }).then((res) => {
        console.log(res, '新增/修改')
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()

          this.$myVS.openNoti(res.data.Message, '', undefined)

          // 若是新增
          if (this.isNewPost) {
            this.alertAfterCreated = true // 呈現alert
            this.alertTitle = this.title
            // this.$router.replace({
            //   // 跳轉到新頁面
            //   name: 'edit-post',
            //   params: {
            //     id: res.data.Data.sbpno,
            //   },
            // })
            this.$router.replace({
              name: 'posts',
            })
          } else {
            // this.loadPost()
            this.$router.replace({
              name: 'posts',
            })
          }
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 2.5.10 文章-刪除
    deletePost() {
      const loading = this.$vs.loading({
        target: this.$refs.deletePostBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/pages/blogs/dPages.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          sbpno: this.$route.params.id,
        },
      }).then((res) => {
        if (res.data.Success) {
          loading.close() // 關閉按鈕loading
          this.deletePost_dialog = false // 關閉dialog
          this.$myVS.openNoti(res.data.Message, '', undefined)

          this.$router.replace({
            name: 'posts',
          })
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close() // 關閉按鈕loading
        }
      })
    },
    // 留言checkbox選中觸發
    checkBoxHandleClick(e) {
      setTimeout(() => {
        if (this.formSelected.length > 0 && this.formSelected.length < this.formMsg.length) {
          // 勾選卻沒有全選時
          this.formIndeterminate = true
        } else if (this.formSelected.length === this.formMsg.length) {
          // 全選時
          this.formIndeterminate = false
          this.$nextTick(() => {
            this.formSelectAll = true
          })
        } else {
          // 沒選時
          this.formIndeterminate = false
          this.formSelectAll = false
        }
      }, 0)
    },
    // 中間狀態checkbox選中觸發
    indeterminateHandleClick(e) {
      let value = e.target.checked
      this.checkAllHandleClick(null, value)
    },
    // 全選checkbox選中觸發
    checkAllHandleClick(e, status) {
      let value
      e ? (value = e.target.checked) : (value = status)
      if (value) {
        for (let i = 0; i < this.formMsg.length; i++) {
          this.formSelected.push(this.formMsg[i].sbpino)
        }
      } else {
        this.formSelected = []
      }
    },
    // 改變頁籤時觸發
    async changePagination() {
      this.selected = []
      this.selectAll = false
      this.indeterminate = false

      let isSuccess = await this.loadForm() // 改變頁籤時讀取表單列表
      // 讀取成功後smooth scroll
      if (isSuccess) {
        let scrollTo = this.$refs.scrollTo
        scrollTo.scrollIntoView({
          behavior: 'smooth',
        })
      }
    },
    // 2.5.13 文章-讀取表單列表
    loadForm() {
      return this.$axios({
        url: 'front/pages/blogs/getPagesInFormList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          page: this.currentPage,
          title: '',
          sbno: '',
          sbpno: this.$route.params.id,
        },
      }).then((res) => {
        console.log(res, '2.5.13 文章-讀取表單列表')
        if (res.data.Success) {
          this.formMsg = res.data.Data
          this.pagination = res.data.Page

          return res.data.Success
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 2.5.14 文章-修改表單回覆
    sentMsg(sbpino, index, invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs['sentBtn' + index][0],
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/pages/blogs/uPagesInForm.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          sbpno: this.$route.params.id,
          sbpino: sbpino,
          recont: this.formReplyMsg,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.formReplyMsg = ''
          this.replyTextareaIndex = null
          this.loadForm()
          loading.close()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 2.5.15 文章-刪除表單
    deleteMsg() {
      const loading = this.$vs.loading({
        target: this.$refs.deleteMsgBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/pages/blogs/dPagesInForm.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          no: this._selectedToStr,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.formSelected = []
          this.formIndeterminate = false
          this.formSelectAll = false
          loading.close() // 關閉按鈕loading
          this.deleteMsg_dialog = false // 關閉dialog
          this.$myVS.openNoti(res.data.Message, '', undefined)

          this.currentPage = 1
          // 重新讀取留言資料

          this.loadForm().then((res) => {
            // 讀取成功後smooth scroll
            if (res) {
              let scrollTo = this.$refs.scrollTo
              scrollTo.scrollIntoView({
                behavior: 'smooth',
              })
            }
          })
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close() // 關閉按鈕loading
        }
      })
    },

    // 2.5.11 網站文章-複製
    copyPost() {
      const loading = this.$vs.loading({
        target: this.$refs.copyMsgBtn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/pages/blogs/copyPages.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          sbpno: this.$route.params.id,
        },
      }).then((res) => {
        if (res.data.Success) {
          loading.close() // 關閉按鈕loading
          this.copy_dialog = false // 關閉dialog
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.$router.push({
            name: 'posts',
          })
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close() // 關閉按鈕loading
        }
      })
    },
    // 2.5.1 部落格列表-讀取
    getBlogsList() {
      this.$axios({
        url: 'front/pages/blogs/getBlogsList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          page: -1,
          title: '',
        },
      }).then((res) => {
        if (res.data.Success) {
          this.blogs = res.data.Data
          this.isShowBlogMsg = true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    uploadImg(e) {
      if (!e.target.files[0]) return
      if (e.target.files[0].type.indexOf('image') === -1) {
        this.$myVS.openNoti('發生錯誤，請稍候再試', '僅限圖片格式上傳', 'danger', 10000)
        return
      }
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = (e) => {
        this.img = e.target.result
      }
      e.target.value = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.box {
  @apply bg-white rounded-xl p-4 space-y-6;
}

.title {
  @apply text-sm font-medium text-gray-text_dark mb-1;
}

.bigTitle {
  @apply text-base font-medium text-gray-text_dark;
}

.replyMsg_section {
  @apply px-4 mr-0 ml-auto space-y-4 relative;
  width: calc(100% - 23px - 16px);

  // 直線
  &::before {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    background: var(--gray-divide);
    left: calc(0px - 11.5px);
  }

  .replied_section {
    position: relative;
    // 彎曲的線
    &::before {
      content: '';
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      border: 2px solid var(--gray-divide);
      clip-path: circle(55% at 0 100%);
      left: -27px;
      top: 30%;
    }
  }
}

.upload {
  @apply max-w-full border-2 rounded-xl border-dashed border-gray-200 overflow-hidden flex items-center justify-center hover:border-gray-divide transition-colors cursor-pointer relative;

  &.aspect-ratio-1-1_global_style {
    padding-bottom: calc(100% - 4px);
  }
}

.uploadImg {
  @apply absolute top-1/2 transform -translate-y-1/2;
  width: 100%;
  @screen md {
    max-width: 261px;
  }
}

.upload_icon {
  @apply flex flex-col items-center absolute top-1/2 transform -translate-y-1/2;
}

.img_input {
  @apply border-2 absolute z-10 h-full w-full cursor-pointer opacity-0 top-1/2 transform -translate-y-1/2;
}

::v-deep #vs-input--noBorderInput {
  border: none;
  padding-left: 0px;
}

::v-deep .formCheckbox .vs-checkbox-label {
  padding-left: 16px;
}
</style>
